import React from "react";
import Text from "../components/Text/Text";
import Layout from "../components/layout";
import MainMenu from "../components/MainMenu/MainMenu";
import Footer from "../components/Footer/Footer";
import CopyRights from "../components/Footer/CopyRights/CopyRights";
import SEO from "../components/seo";
import "../assets/css/main.css";
import Header from "../components/Header/Header";
import HeadingCTA from "../components/HeadingCTA/HeadingCTA";
import { StaticImage } from "gatsby-plugin-image";
import ContactUs from "../components/ContactUs/ContactUs";
import Summary from "../components/CaseStudy/Summary/Summary";
import { useStaticQuery, graphql } from "gatsby";
import Locations from "../components/Locations/Locations";
import Img from "gatsby-image";

const content = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "about/aboutbanner.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      overviewImage: file(
        relativePath: { eq: "casestudy/CASE-STUDY-DAKAR-OVERVIEW.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 444) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <MainMenu />
      <SEO
        seo={{
          shareImage: data.bannerImage.childImageSharp.fixed.src,
          metaTitle: "About Us - Transportation & Consulting Solution",
          metaDescription:
            "MOTUS | ONE transportation management & consulting solutions provide the highest level of service and quality by using technology to reduce cost.",
        }}
      />
      <Header
        image={
          <StaticImage
            src="../assets/images/about/aboutbanner.jpg"
            draggable="false"
          />
        }
        halfHeight
        breadcrumbs={[{ label: "About us" }]}
      >
        <HeadingCTA
          dark
          title={"About MOTUS | ONE - Transportation & Consulting Solutions"}
          h1
        />
      </Header>

      <Summary
        title={"Our promise, delivered to every guest, every time"}
        isFlipped={true}
        customWidth={600}
        customHeight={800}
        image={
          <StaticImage
            src="../assets/images/about/transport.jpg"
            draggable="false"
          />
        }
        description={
          <>
            <p>
              For producers of world-famous events who must deliver for every
              guest every time, MOTUS | ONE is the transport management team
              that designs and delivers transport solutions that ensure the
              complex is made simple and your promises are kept. We have the
              experience, creativity, and technology to get the job done right.
            </p>

            <p>
              For businesses who need their people in the right place at the
              right time, MOTUS | ONE provides transport services with a
              commitment to service and rigor that only comes from mastering the
              demanding world of events.
            </p>
          </>
        }
      />

      <Summary
        title={"The best in leadership and technology"}
        isFlipped={false}
        customWidth={600}
        customHeight={800}
        image={
          <StaticImage
            src="../assets/images/events/EVENT_TRANSPORT_TECHNOLOGY.png"
            alt="motus one rde transport application"
            objectFit="contain"
          />
        }
        description={
          <>
            <p>
              Our leaders and managers work at every level – from C-suite
              meeting rooms to on-the-ground ops – to plan and deliver
              transformative change.
            </p>

            <p>
              Behind the scenes, we implement technology that simplifies all
              types of projects – from niche one-off events to the entire
              transport infrastructure.
            </p>

            <p>
              We integrate in-vehicle and back-office systems to give our
              clients unprecedented monitoring and response capabilities. This
              applies to passengers too, enabling you to satisfy their needs
              through better communication, tracking, and planning.
            </p>
          </>
        }
      />

      <Summary
        title={"One provider means less hassle for you"}
        isFlipped={true}
        customWidth={500}
        customHeight={700}
        image={
          <StaticImage
            src="../assets/images/about/oneprovider.jpg"
            draggable="false"
          />
        }
        description={
          <>
            <p>
              You can save significant time and effort by hiring us as the sole
              provider. By reducing your supplier footprint, you also reduce
              your admin burden. This in turn frees up your people and resources
              so that they can be deployed where they’re needed most.
            </p>

            <p>
              You’ll have a single point of contact who will be available at all
              times and will be fully accountable. They’ll ensure that each
              project milestone is met on time and to budget, and they’ll work
              diligently and honestly to build a stable and mutually beneficial
              relationship.
            </p>
          </>
        }
      />

      <Summary
        title={"All you need in one place"}
        isFlipped={false}
        customWidth={600}
        customHeight={600}
        image={
          <StaticImage
            src="../assets/images/about/allyouneed.jpg"
            draggable="false"
          />
        }
        description={
          <>
            <p>
              We work end-to-end with our clients – from feasibility studies and
              conceptualizing through to final commissioning and day-to-day
              operations. Pick what you need from our suite of services: event
              transport solution; vehicles and FF&E; staff training and
              provision; logistics; VIP care; fleet procurement and management;
              technology and reporting systems; project bidding; brand
              optimization and more.
            </p>
          </>
        }
      />

      <Text
        isFlipped={false}
        header={"Our objectives"}
        text={
          <ul>
            <li>
              To work with our clients with integrity, in a spirit of genuine
              partnership
            </li>
            <li>
              To deliver the highest operational standards of efficiency,
              safety, and reliability
            </li>
            <li>
              To be the most sought-after transportation partner thanks to our
              capabilities and ethics
            </li>
          </ul>
        }
      />

      <Text
        isFlipped={false}
        header={"Our vision"}
        text={
          <>
            To be the industry trailblazer who delivers exceptional outcomes for
            the most demanding clients and challenging projects.
          </>
        }
      />

      <Text
        isFlipped={false}
        header={"Our mission"}
        text={
          <>
            Our missions are to push aside the old so that every single guest
            gets an experience right for them.
          </>
        }
      />

      <Text
        isFlipped={false}
        header={"Care to join us?"}
        text={
          <>
            <p>
              We’re always looking for talented people who have a strong
              industry track record. But credentials alone won’t be enough. We
              need true team-players – people whose ethics and diligence will
              make a profound difference to our team and our clients’
              organizations."
            </p>

            <p>
              <b>We’re looking for people experienced in:</b> Transport
              consulting, Transport logistics and Valet services.
            </p>
          </>
        }
      />
      <Locations />
      <ContactUs title={""} />

      <Footer />
      <CopyRights />
    </Layout>
  );
};

export default content;
